import type { InstructionItemProps } from '@/types/recipe';
import type { RecipeProps } from '@/components/raven/content/Recipe/';

type HowToStep = {
	'@type': 'HowToStep';
	text: string;
};

type HowToSection = {
	'@type': 'HowToSection';
	itemListElement: HowToStep[];
	name?: string;
};

/**
 * The function `generateSchemaInstructions` converts recipe instructions into structured data for
 * schema.org's HowTo schema.
 * @param instructions - The `generateSchemaInstructions` function takes an array of `instructions` as
 * input, where each instruction can be a single step or a group of steps. It then transforms this
 * input into a structured format suitable for generating schema instructions for a recipe.
 * @returns The function `generateSchemaInstructions` returns an array of `HowToSection` objects or an
 * array of `HowToStep` objects, depending on the input `instructions`.
 */
export function generateSchemaInstructions(
	instructions: RecipeProps['instructions'],
): HowToSection[] | HowToStep[] {
	const instructionGroups: HowToSection[] = instructions.flatMap((section) => {
		const groups = section.items ?? [];

		return groups
			.map((group): HowToSection | null => {
				const rawSteps = (group?.items as InstructionItemProps[]) ?? [];

				const steps: HowToStep[] = rawSteps
					.filter((step): step is { title: string } => !!step?.title)
					.map((step) => ({
						'@type': 'HowToStep',
						text: step.title,
					}));

				if (steps.length === 0) {
					return null;
				}

				const howToSection: HowToSection = {
					'@type': 'HowToSection',
					itemListElement: steps,
				};

				if (group.title) {
					howToSection.name = group.title;
				}

				return howToSection;
			})
			.filter((howToSection): howToSection is HowToSection => !!howToSection);
	});

	return instructionGroups.length === 1
		? instructionGroups[0].itemListElement
		: instructionGroups;
}
