import type { FC } from 'react';
import Script from 'next/script';
import type { NutritionFactsMap } from '@/types/recipe';
import type { Recipe as RecipeSchema } from 'schema-dts';
import { formatRecipeTime } from '@/utils/recipe/format-recipe-time';
import type { RecipeProps } from '@/components/raven/content/Recipe';
import { generateSchemaInstructions } from '@/utils/recipe/generate-schema-instructions';
import { nutritionFactToUnitMap } from '@/components/raven/content/Recipe/NutritionFacts';

export const RecipeJsonLd: FC<RecipeProps> = ({
	cookTime,
	datePublished,
	description,
	featuredImage,
	ingredients,
	instructions,
	keywords,
	nutrition,
	postTitle,
	prepTime,
	servings,
	title,
	totalTime,
}) => {
	const hasIngredients = ingredients && ingredients.length > 0;
	const hasInstructions = instructions && instructions.length > 0;
	const hasNutrition = nutrition && Object.keys(nutrition).length > 0;

	const recipeJsonLd: RecipeSchema = {
		'@type': 'Recipe',
		cookTime: formatRecipeTime(cookTime),
		datePublished,
		description,
		image: featuredImage,
		keywords: keywords || '',
		name: title || postTitle,
		prepTime: formatRecipeTime(prepTime),
		totalTime: formatRecipeTime(totalTime),
	};

	if (servings) {
		recipeJsonLd.recipeYield = `${servings} servings`;
	}

	if (hasIngredients) {
		recipeJsonLd.recipeIngredient = ingredients
			.flatMap((group) => group.items)
			.flatMap((item) => item?.items as string[])
			.filter((ingredient): ingredient is string => ingredient !== undefined);
	}

	if (hasInstructions) {
		recipeJsonLd.recipeInstructions = generateSchemaInstructions(instructions);
	}

	if (hasNutrition) {
		const nutritionValues = Object.entries(nutrition)
			.filter(([key, value]) => {
				// Excluded due to Schema Validation errors
				const schemaExcludedInfo = [
					'monounsaturatedFatContent',
					'polyunsaturatedFatContent',
				];
				return schemaExcludedInfo.indexOf(key) === -1;
			})
			.map(([key, value]) => {
				const unit = nutritionFactToUnitMap[key as NutritionFactsMap];
				return [key, `${value}${unit}`];
			});

		recipeJsonLd.nutrition = {
			'@type': 'NutritionInformation',
			...Object.fromEntries(nutritionValues),
		};
	}

	return (
		<Script id="recipe-json-ld" type="application/ld+json">
			{JSON.stringify({
				'@context': 'https://schema.org',
				...recipeJsonLd,
			})}
		</Script>
	);
};
