import clsx from 'clsx';
import type { FC } from 'react';
import dynamic from 'next/dynamic';
import type { Post } from '@/types/entities';
import type { BlockProps } from '@headstartwp/core/react';

import styles from './styles.module.css';

type SmartSlotBlockProps = {
	item: Partial<Post>;
} & BlockProps;

type SmartSlotBlockAttributes = {
	variant: string;
};

// Prevent component from rendering on
// the server to avoid hydration issues.
const SmartSlot = dynamic(
	() => import('../../SmartSlot').then((mod) => mod.SmartSlot),
	{
		ssr: false,
	},
);

export const SmartSlotBlock: FC<SmartSlotBlockProps> = ({ block, item }) => {
	if (!block) {
		return null;
	}

	const { variant } = block.attributes as unknown as SmartSlotBlockAttributes;

	return (
		<div className={clsx(styles.smartSlotBlock)}>
			<SmartSlot item={item} variant={variant} />
		</div>
	);
};
