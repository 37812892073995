import clsx from 'clsx';
import type { FC } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { differenceInSeconds } from 'date-fns';
import { parseToUtc } from '@/utils/dates/parse-to-utc';

import styles from './styles.module.css';

interface ArticleTimestampProps {
	className?: string;
	date: string;
	modified: string;
	timeZone: string;
}

const getTimezoneAbbreviation = (date: Date, timeZone: string): string => {
	return (
		new Intl.DateTimeFormat('en-US', {
			timeZone,
			timeZoneName: 'short',
		})
			.formatToParts(date)
			.find((part) => part.type === 'timeZoneName')?.value || ''
	);
};

const formatDate = (isoDate: string, timeZone: string) => {
	const date = parseToUtc(isoDate);
	const formattedDate = formatInTimeZone(date, timeZone, 'MMM d, yyyy h:mm a');
	const timezoneAbbreviation = getTimezoneAbbreviation(date, timeZone);

	return {
		date,
		formatted: `${formattedDate} ${timezoneAbbreviation}`,
	};
};

export const ArticleTimestamp: FC<ArticleTimestampProps> = ({
	className = '',
	date,
	modified,
	timeZone,
}) => {
	const created = formatDate(date, timeZone);
	const updated = formatDate(modified, timeZone);

	const isMinimalUpdate = differenceInSeconds(updated.date, created.date) < 60;

	const displayDate = isMinimalUpdate
		? created.formatted
		: `UPDATED: ${updated.formatted}`;

	const updatedDate = isMinimalUpdate
		? created.date.toISOString()
		: updated.date.toISOString();

	return (
		<time
			className={clsx(styles.articleTimestamp, className)}
			dateTime={updatedDate}
		>
			{displayDate}
		</time>
	);
};
